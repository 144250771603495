@import url('https://fonts.googleapis.com/css?family=Montserrat');

.App {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  color: #fff;
}

.App-header {
  background-color: #000;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-title {
  margin: 0;
  font-size: 2.5rem;
  font-weight: 700;
}

.App-content {
  background-color: #222;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-intro {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 500;
}

.App-footer {
  background-color: #000;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
}

.App-heart {
  color: #f00;
  animation: heartbeat 1s infinite;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
